<template>
    <a-layout style="height:100%;">
        <!--<a-breadcrumb style="margin: 6px 10px 0 ;">
            <a-breadcrumb-item>店铺</a-breadcrumb-item>
            <a-breadcrumb-item>店铺配置</a-breadcrumb-item>
        </a-breadcrumb>-->
        <a-layout-content style="height:100%;" class="yj-conten-div_cont" >
            <div class="yj-conten" style="width:calc(100% - 32px); min-width:fit-content;min-height:calc(100% - 48px)" >
                <a-layout style="height:100%;background:#fff;">
                    <a-layout-header style="background:#fff;padding:0 20px; ">
                        <a-radio-group default-value="a" @change="onRadioChange">
                            <a-radio-button value="a">
                                基本信息
                            </a-radio-button>
                            <a-radio-button value="b">
                                常见问题
                            </a-radio-button>
                            <a-radio-button value="c">
                                地点信息
                            </a-radio-button>
                        </a-radio-group>
                    </a-layout-header>

                    <a-layout-content style="padding: 0 20px 16px;  ">
                        <a-form-model ref="ruleForm" :model="formModel" :rules="rules">
                            <a-row>
                                <a-col :span="4">
                                    <a-form-item label="店铺名称" style="margin-bottom:0px;"
                                                 :label-col="formItemLayout.labelCol"
                                                 :wrapper-col="formItemLayout.wrapperCol">

                                        <a-form-model-item has-feedback prop="name">
                                            <a-input placeholder="店铺名称" v-model="formModel.name"></a-input>
                                        </a-form-model-item>

                                    </a-form-item>
                                    <a-form layout="horizontal" style="padding:10px 0px;height:auto;">
                                        <a-form-item label="首页图片" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-upload name="image"
                                                      :data="ParamData"
                                                      list-type="picture-card"
                                                      class="avatar-uploader"
                                                      action="/FileServer/Upload"
                                                      :remove="removeImg"
                                                      :fileList="formModel.fileList"
                                                      @change="imgChange">


                                                <div v-if="formModel.fileList.length < 8">
                                                    <a-icon type="plus" />
                                                    <div class="ant-upload-text">
                                                        上传图片
                                                    </div>
                                                </div>
                                            </a-upload>

                                        </a-form-item>
                                        <a-form-item label="模板选择" style="margin-bottom:24px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">

                                            <div class="yj-storecon-template" :data="templates">
                                                <div v-for="item in templates" v-bind:key="item.ID" @click="chooseTemplate(item.ID)" :class="{'active':item.ID==CurrentTemplate.TemplateID}">
                                                    <img :src="item.BackgroudImg" />
                                                </div>

                                            </div>

                                        </a-form-item>

                                        <a-form-item label="简介" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                    
                                            <Rich v-bind:value.sync="formModel.brief"></Rich>
                                        </a-form-item>

                                    </a-form>

                                </a-col>
                                <a-col :span="7" :offset="1">
                                    <viewStore></viewStore>
                                    <div class="ay-store_img">
                                        <iframe :src="CurrentTemplate.TemplateUrl" frameborder="0" width="100%" height="568" style="overflow:hidden;margin:65px 0px;padding-bottom:65px;"></iframe>
                                    </div>
                                    <!--<a-button>选择</a-button>-->


                                </a-col>

                            </a-row>

                        </a-form-model>
                    </a-layout-content>

                    <a-layout-footer style="height:64px;padding:16px 0 ;background-color:#fff;">
                        <a-row :span="24" type="flex" justify="center">
                            <a-col :span="6">
                                <a-row typeof="flex" justify="space-around" class="textal_c">
                                    <a-col :span="10">
                                        <a-button centered Vertically>取消</a-button>

                                    </a-col>
                                    <a-col :span="10">
                                        <a-button type="primary" @click="save('ruleForm')">保存</a-button>
                                    </a-col>
                                </a-row>



                            </a-col>
                        </a-row>
                    </a-layout-footer>
                </a-layout>

            </div>

        </a-layout-content>


    </a-layout>
</template>

<script type="text/javascript">

    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
    import viewStore from "./ViewStore.vue";
    import { message } from 'ant-design-vue';

    export default {
        name: "BaseInfor",
        components: {            
            Rich, viewStore
        },
        data() {
            return {
                CurrentTemplate: {
                    TemplateUrl: 'http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006',
                    TemplateID:-1,
                },
                modalVisible: false,
                viewImgUrl:"",
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 21 },
                    },
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                templates:[],
                formModel: {
                    templateid:"",
                    name: "",
                    brief: "",
                    fileList: [],
                },
                rules: {
                    name: [{ validator: this.validateUserName, trigger: 'change', required: true }]
                }
                
            };
        },

        methods: {
            chooseTemplate: function (data) {
                var self = this;            
                self.CurrentTemplate.TemplateUrl = 'http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006&templateid=' + data;
                self.CurrentTemplate.TemplateID = data;

            },
            onRadioChange:function(data)
            {
                 var self = this;
                var val = data.target.value;
                console.log(data.target);
                if (val == 'b') {
                    self.$router.push({ name: "ShopManage_QuestionList" });
                } else if (val == 'c') {
                    self.$router.push({ name: "ShopManage_SiteList" });
                }
                console.log(data);
            },
            imgChange: function (info) {
                var self = this;

               if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                   }
                   return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                   // this.$message.success(`${info.file.name} 文件上传成功`);                   
                   // console.log(info.file.response);
                }
                console.log(22222);
                console.log(info);
                self.formModel.fileList = info.fileList;
                

            },
            removeImg: function (data) {
                //var self = this;
                console.log(data);
                //self.formModel.fileList = data.fileList;

            },
            onEditorReady: function () {

            },
            onEdiorChange: function () {

            },
            validateUserName: function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('请输入名称'));
                } else {
                    callback();
                }
            },
            getData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopManageDetail",
                    data: {
                        custmerid: 'dsi201909240001'
                    },
                    OnSuccess: function (data) {
                        console.log("global");
                        console.log(data);
                        self.formModel.name = data.data.Name;
                        self.formModel.brief = data.data.Brief;
             
                        self.formModel.fileList = data.data.UploadImgs;
                        self.formModel.templateid = data.data.TemplateID;
                        self.CurrentTemplate.TemplateID = data.data.TemplateID;                        
                    }
                };
                http.Post(op);
            },
            getTemplateData:function(){
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetTemplateList",
                    data: {
                       // custmerid: 'dsi201909240001'
                    },
                    OnSuccess: function (data) {                   
                        self.templates = data.data;
                    }
                };
                http.Post(op);
            },
            save: function (formName) {
                var self = this;
                console.log(self.formModel.brief);

                this.$refs[formName].validate(valid => {
                    if (valid) {
                       
                        var op = {
                            url: "/ShopModule/ShopManage/UpdateShopMange",
                            data: {                               
                                Name: self.formModel.name,
                                Brief: self.formModel.brief,
                                UploadImgs: self.formModel.fileList,
                                TemplateID: self.CurrentTemplate.TemplateID
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                if (data.data == "True") {
                                    message.success(
                                        '编辑成功',
                                        2,
                                    );
                                }

                            }
                        };
                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            var self = this;
            self.getData();
            self.getTemplateData();
        },
        computed: {
            
        }

    };
</script>
<style type="text/css">
    .phone-frame {
        border: 1px solid #d5d5d5;
        border-radius: 10px;
        width: 100%;
        height: 768px;
        position: relative;
        overflow: hidden;
        background: url('/assets/img/modules.png') no-repeat top center;
        background-size: 100%;
    }

    .return-btn {
        position: absolute;
        left: 0;
        top: 30px;
    }

    .share-btn {
        position: absolute;
        right: 0;
        top: 30px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
       /* filter: grayscale(80%);*/
    }
</style>


