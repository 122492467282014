<template>
    <div>
        <a-button type="primary" @click="() => (modalVisible = true)">预览</a-button>
        <a-modal v-model="modalVisible" title="微信扫码预览">

            <div style="text-align:center;">
                <img :src="viewImgUrl" height="360" />
            </div>
            <div slot="footer"></div>
        </a-modal>
    </div>
</template>

<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    export default {
        name: "viewstore",
        data() {
            return {
                modalVisible: false,
                viewImgUrl:""
            };
        },
        methods: {
            getStoreView() {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetStoreViewQr",
                    data: {
                    },
                    OnSuccess: function (data) {                       
                        self.viewImgUrl = data.data;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            var self = this;
            self.getStoreView();
        },
    };
</script>